var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "absolute grid grid-cols-6 grid-rows-3 inset-0 w-full h-full bg-grey bg-opacity-75 z-500",
    },
    [
      _c(
        "div",
        { staticClass: "col-start-3 col-end-5 row-start-2 row-end-3" },
        [
          _c("vx-card", [
            _c(
              "div",
              {
                staticClass:
                  "gap-8 w-full h-full flex flex-col justify-between items-center",
              },
              [
                _c(
                  "label",
                  { staticClass: "block w-full text-center text-xl font-bold" },
                  [
                    _vm._v(
                      " " + _vm._s(_vm.$t("voce-pausou-o-questionario")) + " "
                    ),
                  ]
                ),
                _c(
                  "vs-button",
                  {
                    attrs: { icon: "play_arrow", color: "success" },
                    on: { click: _vm.leavePause },
                  },
                  [_vm._v(_vm._s(_vm.$t("retomar")))]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }