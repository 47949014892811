<template>
  <div class="grid grid-cols-12 gap-4">
    <div class="col-span-12">
      <vs-alert
        :key="pos"
        v-for="(alert, pos) in alerts"
        :title="alert.title"
        :color="alert.color"
        :class="alert.classes">
        <span>{{ alert.text }}</span>
      </vs-alert>
    </div>
    <div class="col-span-12">
      <!-- <label class="text-sm pl-1">Justificativa</label> -->
      <vs-textarea :label="$t('data_viewer.keys.justification')" v-model="content" />
      <span class="text-danger text-sm" v-show="errors.has('content')">{{
        errors.first('content')
      }}</span>
    </div>
    <div class="col-span-12">
      <vs-button
        v-if="showCancelButton"
        class="float-right"
        color="primary"
        type="border"
        @click="cancel"
        >{{ $t('action.cancel') }}</vs-button
      >
      <vs-button
        :disabled="!validJustification"
        class="float-right mr-2"
        @click="save"
        >{{ $t('action.save') }}</vs-button
      >
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    content: ''
  }),
  props: {
    alerts: {
      type: Array,
      default: () => []
    },
    showCancelButton: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    validJustification() {
      if (this.content.length > 150) {
        this.errors.add({
          field: 'content',
          msg: this.$t('maximo-de-150-caracteres-na-justificativa')
        })
        return false
      }
      return !this.isEmpty(this.content)
    }
  },
  methods: {
    save() {
      this.$emit('save', this.content)
      this.content = ''
    },
    cancel() {
      this.$emit('cancel')
      this.content = ''
    }
  }
}
</script>

<style>

</style>