var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.questionClasses }, [
    _c(
      "div",
      {
        staticClass: "w-full",
        staticStyle: { "border-bottom": "1px solid #dadce0" },
      },
      [
        _c("vs-icon", {
          class: [
            { "revision-color": _vm.revisionMarked },
            "select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1",
            { "cursor-pointer": !_vm.disabled },
          ],
          attrs: {
            icon: _vm.revisionMarked ? "bookmark" : "bookmark_border",
            size: "1.5rem",
          },
          on: {
            click: function ($event) {
              return _vm.revisionMarkToggle()
            },
          },
        }),
        _c("div", { staticClass: "flex mb-2 mt-4 w-full select-none" }, [
          _c("div", { staticClass: "bg-grid-color-secondary mr-2 w-full" }, [
            _c("div", { staticClass: "m-1 flex gap-2" }, [
              _c("b", [
                _vm._v(_vm._s(_vm.$t("questao-this-index", [this.index]))),
              ]),
              !_vm.hide_score
                ? _c("label", [
                    _vm._v(
                      _vm._s(
                        _vm.item.data.score
                          ? "(" +
                              _vm.$t("valor") +
                              ": " +
                              _vm.formattedScore +
                              ")"
                          : ""
                      )
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", {
              directives: [
                {
                  name: "html-safe",
                  rawName: "v-html-safe",
                  value: _vm.title,
                  expression: "title",
                },
              ],
              staticClass: "editor-content ck-content ml-3 mr-4 unselectable",
              attrs: { id: _vm.titleId },
            }),
          ]),
        ]),
      ],
      1
    ),
    _c("div", { staticClass: "flex sm:w-full" }, [
      _c(
        "div",
        { staticClass: "bg-grid-color-secondary ml-2 mr-2 mb-2 pt-2 w-full" },
        [
          _vm.showShortInput
            ? _c("vs-input", {
                staticClass: "w-full",
                attrs: { disabled: _vm.disabled },
                on: { input: _vm.valueChange },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              })
            : _c("vs-textarea", {
                ref: "textarea",
                staticClass: "w-full",
                attrs: { disabled: _vm.disabled },
                on: { input: _vm.valueChange },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("value"),
                  expression: "errors.has('value')",
                },
              ],
              staticClass: "text-danger text-sm",
            },
            [_vm._v(_vm._s(_vm.errors.first("value")))]
          ),
        ],
        1
      ),
    ]),
    _vm.item.data.character_limit
      ? _c(
          "div",
          {
            class: [
              "float-right pr-1",
              { "character-limit-exceeded-class": _vm.characterLimitExceeded },
            ],
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "quantidade-caracteres-charactersqty-de-item-data-character_limit",
                    [_vm.charactersQty, _vm.item.data.character_limit]
                  )
                ) +
                " "
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }