var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.step === 1
      ? _c("div", { staticClass: "flex flex-col overflow-hidden" }, [
          _c(
            "header",
            { staticClass: "w-full text-center border-b border-grey p-4" },
            [
              _c("logo", {
                attrs: {
                  reduce: false,
                  logoStyle: "max-width: 450%; height: auto; max-height: 40px;",
                },
              }),
            ],
            1
          ),
          _c("main", { staticClass: "flex-1 pr-24 pl-24" }, [
            _c(
              "div",
              { staticClass: "grid grid-cols-1 sm:grid-cols-2 h-full p-8" },
              [
                _c("div", { staticClass: "h-full" }, [
                  _c("div", { staticClass: "flex mt-4 justify-center" }, [
                    _c("h3", [
                      _vm._v(_vm._s(_vm.$t("visualizacao-do-dispositivo"))),
                    ]),
                  ]),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.userMediaStream !== null,
                          expression: "userMediaStream !== null",
                        },
                      ],
                      staticClass: "flex items-center justify-center mt-4",
                    },
                    [
                      _c("video", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.userMediaStream !== null,
                            expression: "userMediaStream !== null",
                          },
                        ],
                        staticClass: "video-player-document transform w-full",
                        attrs: {
                          id: "video-document-test",
                          muted: "",
                          disablePictureInPicture: "",
                        },
                        domProps: { muted: true },
                      }),
                    ]
                  ),
                  _vm.getDeviceVideoError !== null
                    ? _c(
                        "div",
                        {
                          staticClass: "flex items-center justify-center mt-4",
                        },
                        [
                          _c("img", {
                            staticStyle: { "max-width": "300px" },
                            attrs: {
                              src: require("@/assets/images/no_image_placeholder.png"),
                              alt: "$t('no-camera')",
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex items-center justify-center mt-4" },
                    [
                      _c("vs-divider", [
                        _vm._v(_vm._s(_vm.$t("validacao-de-microfone"))),
                      ]),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "grid grid-rows-2 mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-center" },
                      [
                        _c(
                          "vs-progress",
                          {
                            attrs: { percent: _vm.micVolume, color: "success" },
                          },
                          [_vm._v("success")]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex items-center justify-center" },
                      [
                        !_vm.micSuccess
                          ? _c(
                              "span",
                              { staticClass: "mic-config mt-2 text-center" },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "fale-algo-em-voz-alta-para-validar-a-configuracao-do-seu-microfone"
                                    )
                                  )
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.micSuccess
                          ? _c("span", { staticClass: "mic-succes mt-2" }, [
                              _vm._v(_vm._s(_vm.$t("sucesso") + "!")),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "grid grid-rows-6 p-4" }, [
                  _c("div", { staticClass: "pt-8" }, [
                    _vm.videoDevices.length > 0
                      ? _c(
                          "div",
                          [
                            _c(
                              "vs-select",
                              {
                                staticClass: "w-full",
                                attrs: {
                                  label: _vm.$t("entradas-de-video-camera"),
                                },
                                on: { input: _vm.enableUserMedia },
                                model: {
                                  value: _vm.selectedVideoDeviceId,
                                  callback: function ($$v) {
                                    _vm.selectedVideoDeviceId = $$v
                                  },
                                  expression: "selectedVideoDeviceId",
                                },
                              },
                              _vm._l(
                                _vm.videoDevices,
                                function (device, index) {
                                  return _c("vs-select-item", {
                                    key: index,
                                    attrs: {
                                      value: device.deviceId,
                                      text: device.label,
                                    },
                                  })
                                }
                              ),
                              1
                            ),
                            _vm.getDeviceVideoError !== null
                              ? _c(
                                  "vs-alert",
                                  {
                                    staticClass: "mt-2 h-auto",
                                    attrs: {
                                      active: "true",
                                      color: "danger",
                                      icon: "new_releases",
                                    },
                                  },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.getDeviceVideoError
                                        ),
                                      },
                                    }),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _vm.audioDevices && _vm.audioDevices.length > 0
                    ? _c(
                        "div",
                        { staticClass: "mt-4" },
                        [
                          _c(
                            "vs-select",
                            {
                              staticClass: "w-full",
                              attrs: {
                                label: _vm.$t("entradas-de-audio-microfone"),
                              },
                              on: { input: _vm.enableUserMedia },
                              model: {
                                value: _vm.selectedAudioDeviceId,
                                callback: function ($$v) {
                                  _vm.selectedAudioDeviceId = $$v
                                },
                                expression: "selectedAudioDeviceId",
                              },
                            },
                            _vm._l(_vm.audioDevices, function (device, index) {
                              return _c("vs-select-item", {
                                key: index,
                                attrs: {
                                  value: device.deviceId,
                                  text: device.label,
                                },
                              })
                            }),
                            1
                          ),
                          _vm.getDeviceAudioError !== null
                            ? _c(
                                "vs-alert",
                                {
                                  staticClass: "mt-2 h-auto",
                                  attrs: {
                                    active: "true",
                                    color: "danger",
                                    icon: "new_releases",
                                  },
                                },
                                [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.getDeviceAudioError
                                      ),
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "flex items-start justify-end" },
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "mr-2",
                          attrs: {
                            icon: "icon icon-log-in",
                            "icon-pack": "feather",
                            disabled:
                              !_vm.micSuccess || _vm.userMediaStream === null,
                          },
                          on: { click: _vm.verify },
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("avancar")) + " ")]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]),
        ])
      : _c(
          "div",
          {
            staticClass:
              "flex w-full bg-img vx-row no-gutter items-center justify-center",
          },
          [
            _c("div", { staticClass: "flex flex-col ..." }, [
              _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c("logo", {
                    attrs: {
                      reduce: false,
                      logoStyle:
                        "max-width: 450%; height: auto; max-height: 40px;",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "text-center mb-6" }, [
                _c("h1", [_vm._v(_vm._s(_vm.$t("checking-connectivity")))]),
              ]),
              _c("div", { staticClass: "flex items-center justify-center" }, [
                _c(
                  "div",
                  {
                    staticClass: "grid sm:grid-cols-2 grid-cols-1 gap-8 p-4",
                    staticStyle: { "max-width": "80%" },
                  },
                  _vm._l(_vm.configs, function (config) {
                    return _c(
                      "div",
                      { key: config, class: config.class },
                      [
                        _c(
                          "vs-card",
                          [
                            _c(
                              "div",
                              {
                                staticClass: "font-bold",
                                staticStyle: { "text-align": "center" },
                                attrs: { slot: "header" },
                                slot: "header",
                              },
                              [_vm._v(" " + _vm._s(_vm.$t(config.title)) + " ")]
                            ),
                            _c("div", { staticClass: "grid grid-cols-2" }, [
                              _c(
                                "div",
                                { staticClass: "flex items-center text-lg" },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t(config.description)) +
                                      " "
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                [
                                  _c("lottie-animation", {
                                    attrs: {
                                      path: config.icon,
                                      width: 128,
                                      height: 128,
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                            _vm._l(config.items, function (item) {
                              return _c(
                                "div",
                                { key: item, staticClass: "grid grid-cols-1" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "grid grid-cols-12" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-xl col-span-1" },
                                        [
                                          _c("font-awesome-icon", {
                                            staticClass: "mr-4",
                                            attrs: { icon: item.icon },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-xl col-span-6" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t(item.name)) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "text-xl col-span-5" },
                                        [
                                          _vm._v(
                                            " " + _vm._s(item.message) + " "
                                          ),
                                          item.status ===
                                          _vm.proctoringTestStatusSuccess
                                            ? _c("font-awesome-icon", {
                                                staticClass: "text-green",
                                                attrs: { icon: "check-circle" },
                                              })
                                            : _vm._e(),
                                          item.status ===
                                          _vm.proctoringTestStatusError
                                            ? _c("font-awesome-icon", {
                                                staticClass: "text-red",
                                                attrs: {
                                                  icon: "exclamation-circle",
                                                },
                                              })
                                            : _vm._e(),
                                          item.status ===
                                          _vm.proctoringTestStatusWarning
                                            ? _c("font-awesome-icon", {
                                                staticClass: "text-orange",
                                                attrs: {
                                                  icon: "exclamation-circle",
                                                  title: item.description
                                                    ? item.description
                                                    : "",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  item.description
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "grid grid-cols-12 pt-2",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "text-xl col-span-1",
                                            },
                                            [
                                              _c("font-awesome-icon", {
                                                staticClass: "text-orange",
                                                attrs: {
                                                  icon: "exclamation-circle",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-span-11 flex items-center",
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(item.description)
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ]),
            ]),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }