<template>
  <div class="gap-1 flex-grow flex flex-wrap flex-row items-center justify-between w-full">
    <div class="" style="min-width: 90px; max-width: 105px;">
      <vs-button
        v-if="!reduced"
        type="filled"
        color="warning"
        icon="keyboard_arrow_left"
        class="footer-button p-3 w-full"
        :disabled="disablePrevious"
        @click="emitPrevious"
      >
        {{ $t('common.back') }}
      </vs-button>
      <vs-button
        v-else
        type="filled"
        color="warning"
        icon="keyboard_arrow_left"
        class="footer-button p-3 w-full"
        :disabled="disablePrevious"
        @click="emitPrevious"
      ></vs-button>
    </div>

    <div class=" xl:w-auto">
      <div class="grid w-full justify-items-center">
        {{ paginationInfo }}
      </div>
    </div>

    <div class="" style="min-width: 90px; max-width: 105px;">
      <vs-button
        :color="showDone ? 'success' : 'primary'"
        v-if="!reduced"
        type="filled"
        icon="keyboard_arrow_right"
        class="footer-button buttonnext vs-con-loading__container p-3 w-full"
        :disabled="disableNext"
        @click="emitNext"
      >
        {{showDone ? $t('enviar') : $t('avancar')}}
      </vs-button>

      <vs-button
        v-else
        :color="showDone ? 'success' : 'primary'"
        type="filled"
        :icon="showDone ? 'send' : 'keyboard_arrow_right'"
        class="footer-button buttonnext vs-con-loading__container p-3 w-full"
        :disabled="disableNext"
        @click="emitNext"
      ></vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    reduced: {
      type: Boolean,
      default: false
    },
    disablePrevious: {
      type: Boolean,
      default: false
    },
    disableNext: {
      type: Boolean,
      default: false
    },
    paginationInfo: {
      type: String,
      default: ''
    },
    showDone: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    emitPrevious() {
      this.$emit('previous')
    },
    emitNext() {
      this.$emit('next')
    }
  },
}
</script>

<style>

</style>