<template>
  <div :class="timerClass">
    <span>{{hours}}</span>:<span>{{minutes}}</span>:<span>{{seconds}}</span>
  </div>
</template>
<script>
const millisecond = 1
const second = 1000 * millisecond
const minute = 60 * second
const hour = 60 * minute

export default {
  props: {
    msDelta: {
      type: Number,
      default: 1000
    },
    stopOnZero: {
      type: Boolean,
      default: true
    },
    autoStart: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    }
  },

  model: {
    prop: 'value',
    event: 'tick'
  },

  data: () => ({
    initialTime: 0,
    time: 0,
    interval: null,
    direction: 1,
    ratio: 0,
  }),

  watch: {
    value(val) {
      this.time = val
    },
    time(val) {
      this.$emit('tick', val)
    },
    ratio(val) {
      this.$emit('ratio', val)
    }
  },

  methods: {
    cycle() {
      const delta = this.direction * this.msDelta

      this.time += delta

      if (this.time <= 0) {
        this.$emit('zero')
        if (this.stopOnZero && this.interval) {
          clearInterval(this.interval)
          this.time = 0
        }
      }

      this.ratio = 1 - (this.time / this.initialTime)
    },
    start(direction = 1) {
      this.direction = direction
      this.interval = setInterval(this.cycle, this.msDelta)
    },
    withPadding(val) {
      return String(val).padStart(2, '0')
    },
    startCountdown(time) {
      this.reset(time)
      this.start(-1)
    },
    reset(time = 0) {
      if (this.interval) {
        clearInterval(this.interval)
      }
      this.initialTime = time
      this.time = time
    },
  },

  computed: {
    hours() {
      return this.withPadding(Math.floor(this.time / hour) % 24)
    },
    minutes() {
      return this.withPadding(Math.floor(this.time / minute) % 60)
    },
    seconds() {
      return this.withPadding(Math.floor(this.time / second) % 60)
    },
    timerClass() {
      const fourthRemains = 'text-warning'
      const almostZero = 'text-danger'

      if (this.initialTime !== 0) {
        const ratio = this.time / this.initialTime

        if (ratio <= 0.10) {
          return almostZero
        } else if (ratio <= 0.33) {
          return fourthRemains
        } else {
          return 'text-success'
        }
      } else {
        return almostZero
      }
    }
  },

  mounted() {
    if (this.autoStart && this.value > 0) {
      this.startCountdown(this.value)
    }
  },

}
</script>