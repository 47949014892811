
<template>
  <div :class="questionClasses">
    <div class="w-full" style="border-bottom: 1px solid #dadce0">
      <vs-icon
        @click="revisionMarkToggle()"
        :icon="revisionMarked ? 'bookmark' : 'bookmark_border'"
        size="1.5rem"
        :class="[{'revision-color': revisionMarked}, 'select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1', {'cursor-pointer': !disabled}]"
      />
      <div class="flex mb-2 mt-4 w-full select-none">
        <div class="bg-grid-color-secondary mr-2 w-full">
          <div class="m-1 flex gap-2">
            <b>{{ $t('questao-this-index', [this.index]) }}</b>
            <label v-if="!hide_score">{{item.data.score ? `(${$t('valor')}: ` + formattedScore + ')': ''}}</label>
          </div>
          <div v-html-safe="title" :id="titleId" class="editor-content ck-content ml-3 mr-4 unselectable"></div>
        </div>
      </div>
    </div>
    <div class="flex sm:w-full">
      <div class="bg-grid-color-secondary ml-2 mr-2 mb-2 pt-2 w-full">
        <vs-input
          v-if="showShortInput"
          class="w-full"
          v-model="value"
          @input="valueChange"
          :disabled="disabled"
        />

        <vs-textarea
          v-else
          ref="textarea"
          class="w-full"
          v-model="value"
          @input="valueChange"
          :disabled="disabled"/>

        <span class="text-danger text-sm" v-show="errors.has('value')">{{
          errors.first('value')
        }}</span>
      </div>
    </div>
    <div :class="['float-right pr-1', {'character-limit-exceeded-class': characterLimitExceeded}]" v-if="item.data.character_limit">
      {{ $t('quantidade-caracteres-charactersqty-de-item-data-character_limit', [charactersQty, item.data.character_limit]) }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    answer_id: {
      type: Number,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
    show_required_indicator: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    hide_score: {
      type: Boolean,
      default: false
    },
    disabledInteraction: {
      type: Boolean,
      default: false
    },
    revisionMarked: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    service: null,
    value: '',
  }),
  computed: {
    title() {
      return this.item.data.title ? this.item.data.title : this.$t('sem-titulo')
    },
    titleId() {
      return `title_${this.item.id}`
    },
    questionClasses() {
      return [
        '_simple-text __answer',
        'w-full',
        {
          'text-grey': this.disabled,
          'required-indicator': this.show_required_indicator
        }
      ]
    },
    showShortInput() {
      return this.item.data.input_size === 'short'
    },
    formattedScore() {
      const score = this.item.data.score
      return score ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    },
    disabled() {
      return this.$store.state.formAnswer.disabled || this.disabledInteraction
    },
    characterLimit() {
      return this.item.data.character_limit
    },
    charactersQty() {
      return this.value ? this.value.length : 0
    },
    characterLimitExceeded() {
      return this.value.length >= this.characterLimit
    }
  },
  methods: {
    revisionMarkToggle() {
      if (this.disabled) return
      this.$emit('revisionMarked', this.item.id, !this.revisionMarked)
    },
    valueChange() {

      if (this.characterLimit && this.characterLimitExceeded) {
        this.value = this.value.substring(0, this.characterLimit)
        this.notifyWarning(this.$vs, this.$t('o-limite-de-caracteres-foi-atingido-nao-e-possivel-ultrapassar-a-quantidade-de-this-characterlimit-caracteres', [this.characterLimit]))
      }

      if (!this.preview) {
        this.item.answer = this.value
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          const answer = {
            answer_id: this.answer_id,
            user_id: this.user_id,
            item_id: this.item.id,
            value: this.value
          }
          this.$root.$emit('enqueueAnswer', answer)
        }, 1000)
      }
    },
  },
  mounted() {
    this.parseFormula(this.titleId)
  },
  beforeMount() {
    this.value = this.item.answer ? this.item.answer : ''
  },
}
</script>

<style lang="scss">
  ._simple-text.__answer textarea {
    resize: vertical !important;
    width: 100% !important;
  }
  .character-limit-exceeded-class {
    color: red;
  }
</style>
