import Rest from '../Rest'
import AWS, { S3 } from 'aws-sdk';

export default class AwsService extends Rest {
  /**
   * @type {String}
   */
  static resource = '/api/v1/aws'

  static build(vs: any = null) {
    return new this(this.resource, null, vs)
  }

  assumeRole() {
    return this.get(`assume_role`)
  }

  createConvertJob(id) {
    return this.get(`create_convert_job/${id}`)
  }

  createS3Instance(credentials) {
    const s3 = new AWS.S3();
    s3.config.update({
      region: 'sa-east-1',
      credentials: {
        accessKeyId: credentials.AccessKeyId,
        secretAccessKey: credentials.SecretAccessKey,
        sessionToken: credentials.SessionToken,
      }
    })
    return s3
  }

  createMultipart(s3: S3, fileKey: string) {

    return new Promise((resolve, reject) => {
      
      const multiPartParams: any = {
        Bucket: process.env.VUE_APP_AWS_BUCKET_NAME,
        Key: fileKey,
      }
      
      s3.createMultipartUpload(multiPartParams, (mpErr, multipart) => {
        if (mpErr) { 
          reject(mpErr)
        }
        resolve(multipart)
      })
    })
  }

  uploadPart(s3: S3, blob: Blob, key: string, partNumber: number, uploadId: string, tryNum: number = 0) {
    // const link = document.createElement('a');
    // link.href = URL.createObjectURL(blob);
    // link.setAttribute('download', `${partNumber}.webm`);
    // link.click();

    return new Promise((resolve, reject) => {
      return s3.uploadPart({
        Body: blob,
        Bucket: `${process.env.VUE_APP_AWS_BUCKET_NAME}`,
        Key: key,
        PartNumber: partNumber,
        UploadId: uploadId,
        ContentLength: blob.size
      }, (multiErr, mData) => {

        if (multiErr){
          reject(multiErr)
        }
        resolve(mData)
      })
    })
  }
}
