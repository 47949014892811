<template>
  <div :class="questionClasses">
    <div class="w-full" style="border-bottom: 1px solid #dadce0">
      <vs-icon
        @click="revisionMarkToggle()"
        :icon="revisionMarked ? 'bookmark' : 'bookmark_border'"
        size="1.5rem"
        :class="[
          { 'revision-color': revisionMarked },
          'select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1',
          { 'cursor-pointer': !disabled },
        ]"
      />
      <div class="flex mb-2 mt-4 w-full select-none">
        <div class="bg-grid-color-secondary mr-2 w-full">
          <div class="m-1 flex gap-2">
            <b>{{ $t('questao-this-index', [this.index]) }}</b>
            <label v-if="!hide_score">{{
              item.data.score ? `(${$t('valor')}: ` + formattedScore + ')' : ''
            }}</label>
          </div>
          <div
            v-html-safe="title"
            :id="titleId"
            class="editor-content ck-content ml-3 mr-4 unselectable"
          ></div>
        </div>
      </div>
    </div>
    <div class="flex sm:w-full">
      <div class="bg-grid-color-secondary ml-2 mr-2 mb-2 pt-2 w-full">

        <vs-input
          class="mb-6 mt-2"
          style="width: 80%; margin: 0 auto;"
          v-model="value.title"
          @change="valueChange()"
          placeholder="Título da redação"
        />

        <div class="notebook">
          <div class="page">
            <textarea
              v-model="value.content"
              @input="calcTextDetails($event.target.value)"
              @change="valueChange()"></textarea>
          </div>

          <div class="text-details">
            <div
              class="flex flex-row justify-between rounded-t-sm text-white"
            >
              <p>
                Caracteres: {{ textDetail.characteres }}
              </p>
              <p>
                Palavras: {{ textDetail.words }}
              </p>
              <p class="MuiTypography-root MuiTypography-body1 css-t5g5se">
                Frases: {{ textDetail.phrases }}
              </p>
              <p>
                Parágrafos: {{ textDetail.paragraphs }}
              </p>
            </div>
          </div>

          <div class="flex flex-wrap items-center mt-4">

            <div class="flex w-full justify-center font-bold">
              Estatísticas
            </div>

            <div class="flex mb-4 mt-2 w-full">

              <div class="bg-grid-color-secondary mr-2" style="width: 100%">

                <div class="grid-cols-4 flex">
                  <div class="w-3/12"></div>
                  <div class="w-1/12">
                    <span class="font-bold">Minímo</span>
                  </div>
                  <div class="w-1/12">
                    <span class="font-bold">Máximo</span>
                  </div>
                  <div class="w-7/12"></div>
                </div>

                <div class="grid-cols-4 flex">
                  <div class="w-3/12 font-bold">
                    Qtd. Palavras:
                  </div>
                  <div class="w-1/12">
                    {{ item.data.limits.words.min ? item.data.limits.words.min  : '-'}}
                  </div>
                  <div class="w-1/12">
                    {{ item.data.limits.words.max ? item.data.limits.words.max : '-' }}
                  </div>

                  <div class="w-7/12">
                    <span class="inline-flex items-center" v-if="maxExceeded(item.data.limits.words.max, textDetail.words)">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                        style="padding-top: 2px;"
                      />
                      <span>Máximo de palavras atingido.</span>
                    </span>

                    <span class="inline-flex items-center" v-if="minNotReached(item.data.limits.words.min, textDetail.words)">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                        style="padding-top: 2px;"
                      />
                      <span>Mínimo de palavras ainda não atingido.</span>
                    </span>

                    <span class="inline-flex items-center" v-if="!maxExceeded(item.data.limits.words.max, textDetail.words) && !minNotReached(item.data.limits.words.min, textDetail.words)">
                      <feather-icon
                        icon="CheckIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                        style="padding-top: 2px;"
                      />
                    </span>
                  </div>
                </div>

                <div class="grid-cols-4 flex">
                  <div class="w-3/12 font-bold">
                    Qtd. Frases:
                  </div>
                  <div class="w-1/12">
                    {{ item.data.limits.phrases.min }}
                  </div>
                  <div class="w-1/12">
                    {{ item.data.limits.phrases.max }}
                  </div>

                  <div class="w-7/12">
                    <span class="inline-flex items-center" v-if="maxExceeded(item.data.limits.phrases.max, textDetail.phrases)">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                        style="padding-top: 2px;"
                      />
                      <span>Máximo de frases atingido.</span>
                    </span>

                    <span class="inline-flex items-center" v-if="minNotReached(item.data.limits.phrases.min, textDetail.phrases)">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                        style="padding-top: 2px;"
                      />
                      <span>Mínimo de frases ainda não atingido.</span>
                    </span>

                    <span class="inline-flex items-center" v-if="!maxExceeded(item.data.limits.phrases.max, textDetail.phrases) && !minNotReached(item.data.limits.phrases.min, textDetail.phrases)">
                      <feather-icon
                        icon="CheckIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                        style="padding-top: 2px;"
                      />
                    </span>
                  </div>

                </div>

                <div class="grid-cols-4 flex">
                  <div class="w-3/12 font-bold">
                    Qtd. Parágrafos:
                  </div>
                  <div class="w-1/12">
                    {{ item.data.limits.paragraphs.min }}
                  </div>
                  <div class="w-1/12">
                    {{ item.data.limits.paragraphs.max }}
                  </div>

                  <div class="w-7/12">
                    <span class="inline-flex items-center" v-if="maxExceeded(item.data.limits.paragraphs.max, textDetail.paragraphs)">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                        style="padding-top: 2px;"
                      />
                      <span>Máximo de parágrafos atingido.</span>
                    </span>

                    <span class="inline-flex items-center" v-if="minNotReached(item.data.limits.paragraphs.min, textDetail.paragraphs)">
                      <feather-icon
                        icon="AlertTriangleIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                        style="padding-top: 2px;"
                      />
                      <span>Mínimo de parágrafos ainda não atingido.</span>
                    </span>

                    <span class="inline-flex items-center" v-if="!maxExceeded(item.data.limits.paragraphs.max, textDetail.paragraphs) && !minNotReached(item.data.limits.paragraphs.min, textDetail.paragraphs)">
                      <feather-icon
                        icon="CheckIcon"
                        svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                        style="padding-top: 2px;"
                      />
                    </span>
                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <span class="text-danger text-sm" v-show="errors.has('value')">{{
          errors.first('value')
        }}</span>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null,
    },
    answer_id: {
      type: Number,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
    show_required_indicator: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    hide_score: {
      type: Boolean,
      default: false,
    },
    disabledInteraction: {
      type: Boolean,
      default: false,
    },
    revisionMarked: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    service: null,
    value: {
      title: '',
      content: ''
    },
    numLines: 30,
    text: '',
    textDetail: {
      characteres: 0,
      words: 0,
      phrases: 0,
      paragraphs: 0
    }
  }),
  computed: {
    title() {
      return this.item.data.title ? this.item.data.title : 'Título'
    },
    titleId() {
      return `title_${this.item.id}`
    },
    questionClasses() {
      return [
        '_simple-text __answer',
        'w-full',
        {
          'text-grey': this.disabled,
          'required-indicator': this.show_required_indicator,
        },
      ]
    },
    showShortInput() {
      return this.item.data.input_size === 'short'
    },
    formattedScore() {
      const score = this.item.data.score
      return score ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    },
    disabled() {
      return this.$store.state.formAnswer.disabled || this.disabledInteraction
    },
    characterLimit() {
      return this.item.data.character_limit
    },
    charactersQty() {
      return this.value ? this.value.length : 0
    },
    characterLimitExceeded() {
      return this.value.length >= this.characterLimit
    },
  },
  methods: {
    revisionMarkToggle() {
      if (this.disabled) return
      this.$emit('revisionMarked', this.item.id, !this.revisionMarked)
    },
    essayContentUpdated() {
      console.log('essayContentUpdated...')

    },
    valueChange() {
      console.log('valueChange...', this.value)
      // if (this.characterLimit && this.characterLimitExceeded) {
      //   this.value = this.value.substring(0, this.characterLimit)
      //   this.notifyWarning(
      //     this.$vs,
      //     this.$t(
      //       'o-limite-de-caracteres-foi-atingido-nao-e-possivel-ultrapassar-a-quantidade-de-this-characterlimit-caracteres',
      //       [this.characterLimit]
      //     )
      //   )
      // }

      if (!this.preview) {
        // this.item.answer = this.essay
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          const answer = {
            answer_id: this.answer_id,
            user_id: this.user_id,
            item_id: this.item.id,
            value: this.value,
          }
          this.$root.$emit('enqueueAnswer', answer)
        }, 1000)
      }
    },
    calcTextDetails(text) {
      this.textDetail.characteres = text.length
      if (text.length !== 0) {
        this.textDetail.words = text.split(/\s+/).length
        this.textDetail.phrases = text.split(/[.!?]+/).length
        this.textDetail.paragraphs = text.split(/\n+/).length

      } else {
        this.textDetail.words = 0
        this.textDetail.phrases = 0
        this.textDetail.paragraphs = 0
      }
      this.valueChange()
    },
    maxExceeded(value, current) {
      console.log(value, current);
      if (+current > +value) {
        return true
      }
      return false
    },
    minNotReached(value, current) {
      if (+current < +value) {
        return true
      }
      return false
    }
  },
  mounted() {
    this.parseFormula(this.titleId)
  },
  beforeMount() {
    console.log('this.item...', this.item)
    if (this.item.answer) {
      this.value = this.item.answer
      this.calcTextDetails(this.value.content)
    } else {
      this.value = {}
    }
  },
}
</script>

<style lang="scss">
  ._simple-text.__answer textarea {
    resize: vertical !important;
    width: 100% !important;
  }
  .character-limit-exceeded-class {
    color: red;
  }

  .notebook {
    width: 80%;
    margin: 0 auto;
    background-color: #f9f9f9;
    border: 2px solid #333;
    border-radius: 10px;
    padding: 20px;
  }

  .page {
    background-color: #feefb3;
    background-image: repeating-linear-gradient(
      to bottom,
      transparent,
      transparent 30px,
      #ddd 30px,
      #ddd 32px
    );
    background-size: contain;
    background-repeat: repeat-y;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 20px;
    padding-top: 10px;
  }

  textarea {
    width: 100%;
    height: 800px;
    resize: none;
    border: none;
    background-color: transparent;
    font-family: 'Arial', sans-serif;
    font-size: 16px;
    line-height: 1.5;
    padding: 10px;
    margin-top: -15px;
    line-height: 32px;
  }

  .text-details > div {
    background-color: $primary;
    padding: 0 10px 0 10px;
  }
</style>
