<template>
  <div class="absolute grid grid-cols-6 grid-rows-3 inset-0 w-full h-full bg-grey bg-opacity-75 z-500">
    <div class="col-start-3 col-end-5 row-start-2 row-end-3">
      <vx-card>
        <div class="gap-8 w-full h-full flex flex-col justify-between items-center">
          <label class="block w-full text-center text-xl font-bold"> {{ $t('voce-pausou-o-questionario') }} </label>
          <vs-button icon="play_arrow" color="success" @click="leavePause">{{ $t('retomar') }}</vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import { LeaveQuestionnairePauseEvent } from '@/models/events/DOMEvents'
export default {
  methods: {
    leavePause() {
      const event = new LeaveQuestionnairePauseEvent()
      this.$root.$emit('leaveQuestionnairePause', event)
      this.$emit('leaveQuestionnairePause', event)
    }
  }
}
</script>

<style>

</style>