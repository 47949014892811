<template>
  <div :class="questionClasses">
    <div class="w-full mb-4" style="border-bottom: 1px solid #dadce0">
      <vs-icon
        @click="revisionMarkToggle()"
        :icon="revisionMarked ? 'bookmark' : 'bookmark_border'"
        size="1.5rem"
        :class="[{'revision-color': revisionMarked}, 'select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1', {'cursor-pointer': !disabled}]"
      />
      <div class="flex mb-2 ml-4 mt-4 w-full select-none">
        <div class="bg-grid-color-secondary mr-2 w-full">
          <div class="mb-2 flex gap-2">
            <b>{{ $t('questao-this-index', [this.index]) }}</b>
            <label v-if="!hide_score">{{ item.data.score ? `(${$t('valor')}: ` + formattedScore + ')' : '' }}</label>
          </div>
          <div v-html-safe="title" :id="titleId" class="editor-content ck-content m-2 mr-4 unselectable"></div>
        </div>
      </div>
    </div>

    <div class="options m-4 sm:m-2 md:m-2 lg:-m-2 xl:m-2" :key="key">
      <div v-if="inlineOptions" class="flex gap-3 sm:gap-1 sm:py-2 flex-col">
        <vs-row v-for="(option, index) in options" :key="index">
          <vs-col
            v-if="isEnumerated"
            vs-type="flex"
            vs-justify="center"
            vs-align="left"
            vs-w="1"
            style="width: 20px"
            class="flex items-center justify-center"
          >
            {{ enumerateLabel(index) }}
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="left"
            vs-w="1"
            style="width: 50px"
            class="flex items-center"
          >
            <vs-button
              type="filled"
              :icon="
                option.selected
                  ? 'radio_button_checked'
                  : 'radio_button_unchecked'
              "
              :class="
                option.selected
                  ? 'choose-button checked-button'
                  : 'choose-button'
              "
              @click="optionSelected(option)"
              :disabled="disabled"
            ></vs-button>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="left" vs-align="center" style="width: auto !important;">
            <div
              v-html-safe="option.label"
              @click="optionSelected(option)"
              :class="[{'cursor-pointer': !disabled} ,'select-none editor-content ck-content _option-text']"
            ></div>
          </vs-col>
        </vs-row>
      </div>

      <div v-else>
        <div
          :class="optionsGridClass"
          v-for="opts, i in chunkedOptions"
          :key="opts"
        >
          <vs-row v-for="(option, index) in opts" :key="option" class="mt-2">

            <vs-row class="mt-2">
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <div
                  v-if="isEnumerated"
                  style="width: 20px"
                  class="flex items-center justify-center"
                >
                  {{ enumerateLabel((i * chunkQuantity) + index) }}
                </div>
              </vs-col>
            </vs-row>

            <vs-row>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
              <div
                style="
                  justify-content: center;
                  display: flex;
                  flex-direction: row;
                  overflow: hidden;
                "
                class="cursor-pointer"
                @click="optionSelected(option)"
              >
                <img
                  v-if="option.image && option.image.src"
                  :src="option.image.src"
                  style="height: auto;
                        width: auto;
                        max-width: 200px;
                        max-height: 200px;"
                />
              </div>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <vs-button
                  type="filled"
                  :icon="
                    option.selected
                      ? 'radio_button_checked'
                      : 'radio_button_unchecked'
                  "
                  :class="
                    option.selected
                      ? 'choose-button checked-button'
                      : 'choose-button'
                  "
                  :disabled="disabled"
                  @click="optionSelected(option)"
                  class="cursor-pointer"
                ></vs-button>
              </vs-col>
              <vs-col
                vs-type="flex"
                vs-justify="center"
                vs-align="center"
                vs-w="12"
              >
                <div
                  v-html-safe="option.label"
                  @click="optionSelected(option)"
                  class="cursor-pointer editor-content ck-content unselectable"
                ></div>
              </vs-col>
            </vs-row>


          </vs-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import MediaService from '@/services/api/MediaService'

export default {
  props: {
    item: {
      type: Object,
      default: null
    },
    answer_id: {
      type: Number,
      default: null
    },
    user_id: {
      type: Number,
      default: null
    },
    service: {
      type: Object,
      default: null
    },
    show_required_indicator: {
      type: Boolean,
      default: false
    },
    preview: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: null
    },
    hide_score: {
      type: Boolean,
      default: false
    },
    disabledInteraction: {
      type: Boolean,
      default: false
    },
    revisionMarked: {
      type: Boolean,
      default: false
    },
    bus: {
      type: Object,
      default: null
    },
  },
  data: () => ({
    service: null,
    options: [],
    key: 0,
  }),
  computed: {
    chunkQuantity() {
      return window.innerWidth < 300 ? 1 : window.innerWidth < 600  ? 2 : 3
    },
    optionsGridClass() {
      return `grid gap-4 mt-4 grid-cols-${this.chunkQuantity}`
    },
    chunkedOptions() {
      return _.chunk(this.options, this.chunkQuantity)
    },
    inlineOptions() {
      let inline = true

      // if (window.innerWidth < 600) return false

      if (this.options) {
        for (let index = 0; index < this.options.length; index++) {
          const option = this.options[index]
          if (option.image !== null) {
            inline = false
            break
          }
        }
      }
      return inline
    },
    title() {
      return this.item.data.title ? this.item.data.title : this.$t('sem-titulo')
    },
    titleId() {
      return `title_${this.item.id}`
    },
    questionClasses() {
      return [
        'w-full',
        {
          'text-grey': this.disabled,
          'required-indicator': this.show_required_indicator
        }
      ]
    },
    formattedScore() {
      const score = this.item.data.score
      return score
        ? parseFloat(score)
          .toFixed(2)
          .replace('.', ',')
        : ''
    },
    isEnumerated() {
      return !this.isEmpty(this.item.data.enumerate)
    },
    disabled() {
      return this.$store.state.formAnswer.disabled || this.disabledInteraction
    },
  },
  methods: {
    revisionMarkToggle() {
      if (this.disabled) return
      this.$emit('revisionMarked', this.item.id, !this.revisionMarked)
    },
    optionSelected(option) {
      if (!this.disabled && !option.selected) {
        this.options.forEach(element => {
          element.selected = false
        })
        option.selected = true
        this.key++
        this.item.answer = option.id

        if (!this.preview) {
          const answer = {
            answer_id: this.answer_id,
            user_id: this.user_id,
            item_id: this.item.id,
            value: option.id
          }
          this.$root.$emit('enqueueAnswer', answer)
        }
      }
    },
    enumerateLabel(index) {
      console.log('trying to enumerate index ', index)
      let enumerateStr = ''
      if (!this.isEmpty(this.item.data.enumerate)) {
        if (this.item.data.enumerate === 'number') {
          enumerateStr = index + 1
        } else {
          enumerateStr = String.fromCharCode(65 + index)
        }
        return `${enumerateStr}.`
      }
    },
  },
  mounted() {
    this.parseFormula(this.titleId)

    this.bus.$on('voice-control-select-option', (option) => {
      console.log('voice-control-select', option)
      if (option) {
        this.optionSelected(option)
      }
    })
  },
  beforeMount() {
    this.mediaService = MediaService.build(this.$vs)
    this.options =
      this.item.data && this.item.data.options ? this.item.data.options : []

    if (this.item.data.sort_options) {
      this.options = this.suffle(this.options)
    }
    this.options.forEach(element => {
      element.selected = false
    })

    for (let index = 0; index < this.options.length; index++) {
      const element = this.options[index]
      if (element.id === this.item.answer) {
        element.selected = true
      }
    }
  }
}
</script>
