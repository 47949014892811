
<template>
  <div :class="questionClasses">

    <div class="w-full" style="border-bottom: 1px solid #dadce0">
      <vs-icon
        @click="revisionMarkToggle()"
        :icon="revisionMarked ? 'bookmark' : 'bookmark_border'"
        size="1.5rem"
        :class="[{'revision-color': revisionMarked}, 'select-none hover:animate-bounce transform hover:scale-125 float-right mr-1 pt-1', {'cursor-pointer': !disabled}]"
      />
      <div class="flex flex-col pb-2 pt-4 w-full select-none">
        <div class="p-2 flex gap-2">
          <b>{{ $t('questao-this-index', [this.index]) }}</b>
          <label v-if="!hide_score">{{item.data.score ? `(${$t('valor')}: ` + formattedScore + ')': ''}}</label>
        </div>
        <div v-html-safe="title" :id="titleId" class="editor-content ck-content p-2"></div>
      </div>
    </div>

    <div class="flex sm:w-full" :key="item.id">
      <div class="ml-2 mr-2 mb-2 pt-2 w-full">

        <div v-if="disabled" class="flex flex-wrap justify-center gap-drop">
          <div v-for="item in dragItems" :key="item">
            
            <div :class="['drag-item flex flex-wrap select-none', {'drag-item-disabled': disabled}]">
              <div :class="{'handle': !disabled}">
                {{item.label}}    
              </div>
            </div>
          
          </div>
        </div>

        <drop-list
          v-else
          :items="dragItems"
          @insert="insertOptions"
          mode="cut"
          class="flex flex-wrap justify-center gap-drop"
        >
          <template v-slot:item="{ item }">
            <drag
              :key="item"
              :data="item"
              handle=".handle"
              @cut="remove(dragItems, item)"
            >
              <div :class="['drag-item flex flex-wrap select-none', {'drag-item-disabled': disabled}]">
                
                <div :class="{'handle': !disabled}">
                  <div class="flex items-center">
                    <i
                      class="vs-icon notranslate icon-scale vs-button--icon material-icons mr-2"
                      style="font-size: 20px">
                      drag_indicator
                    </i>
                    {{item.label}}
                  </div>
                </div>
                  
              </div>
            </drag>
          </template>

          <template v-slot:feedback="{ data }">
            <div class="magnetic-gap-drop-item-enter mt-4" :key="data">
              {{data.label}}
            </div>
          </template>
          <div style="height: 200px;"></div>
        </drop-list>

        <div class="flex flex-wrap justify-center mb-6 mt-4">
          <div v-for="(gap, index) in gaps" :key="gap">
            <div v-if="(gap.type === 'magnetic_gap') && gap.droped" class="mt-4">

              <drop-list
                :items="[gap]"
                @dragenter="dragEnter(gap)"
              >
                <template v-slot:item="{ item }">
                  <drag
                    :key="item"
                    :data="item"
                    handle=".handle"
                    @cut="remove(gaps, item)"
                  >

                    <div
                      :class="['magnetic-gap-drop-item flex flex-wrap', { 'handle': !disabled }] ">
                      {{ item.label }}
                    </div>

                    <template v-slot:drag-image>
                      <div class="magnetic-gap-drop-item-enter">
                        {{ item.label }}
                      </div>
                    </template>

                  </drag>

                </template>

                <template v-slot:feedback="{ data }">
                  <div class="item feedback" :key="data">
                    
                  </div>
                </template>
              </drop-list>
              
            </div>
            <div v-if="(gap.type === 'magnetic_gap') && !gap.droped">
              <drop-list
                :items="[gap]"
                @insert="insertGaps($event, index)"
                @dragenter="dragEnter(gap)"
                @dragleave="dragLeave(gap)"
                mode="cut"
              >
                <template v-slot:item="{ item }">
                  <drag
                    :key="item"
                    :data="item"
                    handle=".handle"
                    @cut="remove(gaps, item)"
                  >
                    <div v-if="item.droped" class="magnetic-gap-drop-item-enter mt-4">
                      {{item.label}}
                    </div>
                    <div v-else-if="!item.enter" :class="[{'magnetic-gap-drop-item mt-4' : item.type === 'magnetic_gap'}, 'flex flex-wrap mt-4']">
                      <!-- DON'T REMOVE - BLANK SPACE WITH BORDERS TO DROP -->
                    </div>
                    <div v-else class="magnetic-gap-drop-item-enter mt-4">
                      <!-- DON'T REMOVE - BLANK SPACE TO DROP -->
                    </div>

                  </drag>
                </template>

                <template v-slot:feedback="{ data }">
                  <div class="item feedback" :key="data">
                  </div>
                </template>
              </drop-list>
            </div>

            <div v-if="gap.type === 'gap'" class="gap-drop-item mt-4">
              <TitleComponent
                :debounceUpdate="false"
                v-if="!disabled"
                :item="gap"
                type="gap"
                @title-TITLE_UPDATED="updateOptionTitle"
              ></TitleComponent>
              <span v-if="disabled">
                {{ gap.answer }}
              </span>
            </div>

            <div v-if="gap.type === 'text'" class="mt-4">
              {{gap.label}}
            </div>
          </div>

        </div>

      </div>
    </div>

  </div>
</template>

<script>

import { Drag, Drop, DropList } from 'vue-easy-dnd'
import VueContentLoading from 'vue-content-loading'
import TitleComponent from '@/views/apps/questionnaires/elements/questions/title/TitleComponent'

export default {
  components: { 
    Drag, 
    Drop, 
    DropList,
    VueContentLoading,
    TitleComponent
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    answer_id: {
      type: Number,
      default: null,
    },
    user_id: {
      type: Number,
      default: null,
    },
    service: {
      type: Object,
      default: null,
    },
    show_required_indicator: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      default: null,
    },
    hide_score: {
      type: Boolean,
      default: false
    },
    disabledInteraction: {
      type: Boolean,
      default: false
    },
    revisionMarked: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    service: null,
    value: [],
    dragItems: [],
    gaps: [],
    dragging: false,
    disableOverrided: false,
    timeout: null,
  }),
  computed: {
    title() {
      return this.item.data.title ? this.item.data.title : this.$t('sem-titulo')
    },
    titleId() {
      return `title_${this.item.id}`
    },
    questionClasses() {
      return [
        'w-full',
        {
          'text-grey': this.disabled,
          'required-indicator': this.show_required_indicator
        }
      ]
    },
    formattedScore() {
      const score = this.item.data.score
      return score ? parseFloat(score).toFixed(2).replace('.', ',') : ''
    },
    disabled() {
      return this.$store.state.formAnswer.disabled || this.disabledInteraction
    },
  },
  methods: {
    revisionMarkToggle() {
      if (this.disabled) return
      this.$emit('revisionMarked', this.item.id, !this.revisionMarked)
    },
    answer(debounce = true) {
      if (!this.preview) {

        const answerGaps = []
        this.gaps.filter(gap => {
          if (gap.type === 'gap' || gap.type === 'magnetic_gap') {
            answerGaps.push({
              id: gap.id,
              answer: gap.answer
            })
          }
        })

        if (!this.isEmpty(answerGaps)) {
          this.item.answer = answerGaps
        } else {
          this.item.answer = []
        }
        
        if (debounce) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.emitEnqueue()
          }, 1000)
        } else {
          this.emitEnqueue()
        }
      }
    },
    emitEnqueue() {
      const answer = {
        answer_id: this.answer_id,
        user_id: this.user_id,
        item_id: this.item.id,
        value: this.item.answer
      }
      this.$root.$emit('enqueueAnswer', answer)
    },
    updateOptionTitle(gap, type, value) {
      gap.answer = value
      this.answer(true)
    },
    dragEnter(gap) {
      gap.enter = true
    },
    dragLeave(gap) {
      gap.enter = false
    },
    insertGaps(event, index) {
      const targetGap = this.gaps[index]
      const gap = this._.cloneDeep(event.data)
      gap.id = targetGap.id
      gap.answer = gap.label
      gap.droped = true
      gap.enter = false
      this.gaps.splice(index + 1, 0, gap)
      this.gaps.splice(index, 1)
      this.answer()
    },
    insertOptions(event) {
      if (!this.disabled) {
        const gap = this._.cloneDeep(event.data)
        this.dragItems.push(gap)
      }
    },
    remove(array, value) {
      const index = array.indexOf(value)
      const gap = array[index]
      if (gap && gap.droped) {
        gap.enter = false
        gap.droped = false
        gap.answer = null
        this.gaps.splice(index + 1, 0, gap)
      }
      array.splice(index, 1)
      this.answer()
    },
    fillAnsweredGaps(answers) {
      if (this.gaps && this.gaps.length > 0) {

        // Loop over each gap in this component.
        this.gaps.forEach((gap, position) => {

          /** 
           * If value, which is essentially the answer, is an array.
           * we find the associated value of the current gap and 
           * fill it with its answer.
           * */
          if (this._.isArray(answers)) {

            const answer = answers.find((value) => {
              const id = this._.get(value, 'id', null)
              return id === gap.id
            })

            if (answer) {
              this.gaps[position] = {
                ...gap,
                ...answer,
                label: answer.answer || gap.label
              }
              if (gap.type === 'magnetic_gap' && answer.answer) {
                this.gaps[position].droped = true
              }
              
            }
          }
        })
      }
    },
  },
  mounted() {
    this.parseFormula(this.titleId)
  },
  beforeMount() {
    if (this.item.data && this.item.data.options && this.item.data.options.length > 0) {

      this.gaps = this.item.data.options

      const answers = this._.get(this.item, 'answer', []) || []
      this.fillAnsweredGaps(answers)

      const magneticGaps = this.gaps.filter(gap => {
        return gap.type === 'magnetic_gap'
      })

      const dragItems = this.item.data ? this.item.data.drag_items ? this.item.data.drag_items : [] : []

      const newDragItems = []
      dragItems.forEach(gap => {

        // Filter all drag items not yet associated with some answer
        const magneticsFound = magneticGaps.filter(element => {
          return element.answer === gap.label
        })
        
        if (magneticsFound.length === 0) {
          newDragItems.push({
            'type' : gap.type,
            'label' : gap.label,
            'enter': false,
            'droped': false
          })
        }
      })

      this.dragItems = this._.shuffle(newDragItems)
    }
    this.value = this.item.answer ? this.item.answer : []
    
  },
}
</script>

<style lang="scss">

.gap-drop {
  max-height: 50px;
}

.drag-out, .drag-in {
  margin: 0 10px;
}

.magnetic-gap-drop-item {
  padding: 0 10px;
  height: 25px;
  border-bottom: 1px solid $warning;
  border-right: 1px solid $warning;
  border-left: 1px solid $warning;
  min-width: 100px;
  font-weight: bold;
}

.magnetic-gap-drop-item-enter {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #ccc;
  padding: 0 10px;
  height: 25px;
  min-width: 100px;
  border-radius: 5px;
}

.gap-drop-item {
  margin: 0 10px;
  padding: 0 10px;
  height: 25px;
  border-bottom: 1px solid $primary;
  border-right: 1px solid $primary;
  border-left: 1px solid $primary;
  min-width: 100px;
}

.drag-item {
    min-width: 100px;
    padding: 10px;
    background: #777;
    border-radius: 5px;
    color: white;
}

.drag-item-disabled {
  background: $grey;
}

.drag {
    color: #fff;
    cursor: move;
    background: #777;
    border-right: 2px solid #666;
    border-bottom: 2px solid #666;
}

.drop {
    background: #eee;
    border-top: 2px solid #ccc;
    border-left: 2px solid #ddd;
}

.drop {
  padding: 3px;
  .drag {
    opacity: 0.5;
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
  }
}

.list-item {
  background-color: white;
  border-radius: 8px;
  margin-bottom: 20px;
  background-color: #fff;
}

.handle {
  width: 100%;
  text-align: center;
  cursor: move;
}

.handle > div {
  text-align: center;
}
</style>