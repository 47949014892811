import StreamData from '../models/StreamData'
import AWS from 'aws-sdk'

export default class StreamQueue {

    private queue: Array<StreamData | null | undefined> = []

    private _maxUploadLimit: number = 5000000 // Default to 5Mb
    private _bucketSize: number = 0;

    constructor() {
      this.queue = []
    }
  
    enqueue(item: StreamData | null | undefined) {      
    //   if (item.data.size > 0) {
        // this.increaseBucketSize(item.data.size)
        return this.queue.unshift(item)
    //   }
    //   return null
    }
  
    dequeue(): StreamData | null | undefined {
      return this.queue.pop()
    //   this.decreaseBucketSize()
    //   return blob
    }

    dequeueBySize() {
      const averageSize = this._bucketSize / this.queue.length
      if (averageSize > 0) {
        const bucketsQuantity = this._maxUploadLimit / averageSize
        if (bucketsQuantity > 0 && this._bucketSize >= this._maxUploadLimit) {
          const array = this.queue.splice(this.queue.length - bucketsQuantity, this.queue.length)
          this.decreaseBucketSize(this._bucketSize)
          return array
        }
      }
      return null
    }

    dequeueAll() {
      const array = this.queue.splice(0, this.queue.length)
      this._bucketSize = 0
      return array
    }
  
    peek() {
      return this.queue[this.length - 1]
    }
  
    get length() {
      return this.queue.length
    }
  
    isEmpty() {
      return this.queue.length === 0
    }

    get maxUploadLimit() {
      return this._maxUploadLimit
    }

    set maxUploadLimit(value) {
      this._maxUploadLimit = value
    }

    increaseBucketSize(size: number = 1) {
      this._bucketSize = this._bucketSize + size
    }

    decreaseBucketSize(size: number = 1) {
      this._bucketSize = this._bucketSize - size
    }

    emptyBucketSize() {
      this._bucketSize = 0
    }

    isBucketFull() {
      return this._bucketSize > this.maxUploadLimit
    }
}
  