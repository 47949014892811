/* eslint-disable no-undef */
/* eslint-disable no-invalid-this */
import { isNormalInteger } from '@/util/Util'
import levenshtein from 'js-levenshtein'

export default class SpeechRecognitionProvider {

    private defaultLang: String

    private commands = [
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'ler instruções',
                'description': 'Ler instruções'
              }
            ]
          },
        },
        'event': 'instructions',
        'minimun_distance': 2,
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'iniciar avaliação',
                'description': 'Iniciar avaliação'
              }
            ]
          },
        },
        'event': 'start-questionnaire',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'iniciar questionário',
                'description': 'Iniciar questionário'
              },
              {
                'value': 'iniciar avaliação',
                'description': 'Iniciar avaliação'
              }
            ]
          },
        },
        'event': 'start-questionnaire',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'próxima questão',
                'description': 'Avançar para próxima questão'
              }
            ]
          },
        },
        'event': 'next-question',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'avançar questão',
                'description': 'Avançar para próxima questão'
              }
            ]
          },
        },
        'event': 'next-question',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'questão anterior',
                'description': 'Voltar para questão anterior'
              }
            ]
          },
        },
        'event': 'previous-question',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'voltar anterior',
                'description': 'Voltar para questão anterior'
              }
            ]
          },
        },
        'event': 'previous-question',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'encerrar avaliação',
                'description': 'Encerrar avaliação/questionário'
              },
              {
                'value': 'encerrar questionário',
                'description': 'Encerrar avaliação/questionário'
              }
            ]
          },
        },
        'event': 'finish-questionnaire',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'enviar avaliação',
                'description': 'Enviar avaliação/questionário'
              },
              {
                'value': 'enviar questionário',
                'description': 'Enviar avaliação/questionário'
              }
            ]
          },
        },
        'event': 'finish-questionnaire',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'finalizar avaliação',
                'description': 'Finalizar avaliação/questionário'
              },
              {
                'value': 'finalizar questionário',
                'description': 'Finalizar avaliação/questionário'
              }
            ]
          },
        },
        'event': 'finish-questionnaire',
        'minimun_distance': 2
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'ler questão',
                'description': 'Ler questão'
              }
            ]
          },
        },
        'event': 'read-question',
        'minimun_distance': 4
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'ler alternativas',
                'description': 'Ler alternativas'
              }
            ]
          },
        },
        'event': 'read-options',
        'minimun_distance': 4
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'selecionar alternativa',
                'description': 'Selecionar alternativa'
              }
            ]
          },
        },
        'event': 'select-option',
        'minimun_distance': 4
      },
      {
        'commands': {
          'lang': {
            'pt-BR': [
              {
                'value': 'selecionar opção',
                'description': 'Selecionar opção'
              }
            ]
          },
        },
        'event': 'select-option',
        'minimun_distance': 4
      },
    ]

    private recognition: any

    constructor(lang: String = 'pt-BR') {
      this.defaultLang = lang
      const w: any = window
      const speechRecognition = w.SpeechRecognition || w.webkitSpeechRecognition || w.mozSpeechRecognition || w.msSpeechRecognition
      if (speechRecognition) {
        this.recognition = new speechRecognition()
      

        this.recognition.continuous = true
        this.recognition.interimResults = true
        this.recognition.lang = this.defaultLang

        this.recognition.onresult = (event) => {
          const lastFinalResult = this.getLastFinalResult(event.results)
          if (lastFinalResult) {
            const maxConfidence = this.getMaxConfidence(lastFinalResult)
            this.eventEmit(maxConfidence)
          }
        }
      }
    }

    start = () => {
      console.log('this.recognition', this.recognition)
      this.recognition.start()
    }

    stop = () => {
      if (this.recognition) {
        this.recognition.stop()
      }
    }

    getLastFinalResult = (results: SpeechRecognitionResultList) => {
      const lastResult: SpeechRecognitionResult = results.item(results.length - 1)
      return lastResult.isFinal ? lastResult : null
    }

    getMaxConfidence = (alternatives: SpeechRecognitionResult): SpeechRecognitionAlternative | null => {

      let maxAlternativeConfidence: SpeechRecognitionAlternative | null = null
      if (alternatives.length === 1) {
        return alternatives.item(0)
      } else if (alternatives.length > 1) {
        for (let index = 0; index < alternatives.length; index++) {
          const alternative: SpeechRecognitionAlternative = alternatives.item(index)
          if (!maxAlternativeConfidence) {
            maxAlternativeConfidence = alternative
          } if (alternative.confidence > maxAlternativeConfidence.confidence) {
            maxAlternativeConfidence = alternative
          }
        }
      }
      return maxAlternativeConfidence
    }

    parseCommand = (transcript: string): string | null => {

      let lowestDistance: number | null = null
      let lowestCommand: string | null = null
      this.commands.forEach((cmd) => {
        const dl: any = this.defaultLang
        const cmdLang = cmd['commands'].lang[dl]
        cmdLang.forEach(command => {
          const distance = levenshtein(command.value, transcript.trim())
          console.log('distance', transcript, command, distance)
          if (distance <= cmd.minimun_distance) {
            if (lowestDistance === null && distance >= 0) {
              lowestDistance = distance
              lowestCommand = cmd.event
            } else if (lowestDistance && distance < lowestDistance) {
              lowestDistance = distance
              lowestCommand = cmd.event
            }
          }
        })
      })
      return lowestCommand
    }

    eventEmit = (alternative: SpeechRecognitionAlternative | null) => {

      if (alternative) {
        const command = this.parseCommand(alternative.transcript)
        console.log('parseCommand', command)
            
        switch (command) {
        case 'select-option':
                    
          // eslint-disable-next-line no-case-declarations
          const optionIndex = this.parseSelectQuestion(alternative.transcript)
          console.log('optionIndex...', optionIndex)
          window.dispatchEvent(new CustomEvent(`speech-${command}`, {
            detail: {
              optionIndex: optionIndex
            }
          }))
          break
            
        default:
          window.dispatchEvent(new CustomEvent(`speech-${command}`))
          break
        }
      }
    }

    parseSelectQuestion = (transcript: string) => {

      if (transcript) {
        console.log('transcript', transcript)
        const words = transcript.split(' ')
        if (words && words.length > 0) {
          const word = words[words.length - 1].toUpperCase()
          if (isNormalInteger(word)) {
            return parseInt(word)
          } else {
            return word.charCodeAt(0) - 64
          }
        }
      }

      return null
    }

    getCommands() {
      const dl: any = this.defaultLang

      const commands: any = []
      this.commands.forEach((cmd) => {
        commands.push(cmd['commands'].lang[dl][0])
      })
      return commands
    }
}
