<template>
  <div>
      <div>
        <h4>
            {{ $t('informacoes-gerais-questionnairename-questionnairename', [questionnaireName ? `- ${questionnaireName}` : '']) }}
        </h4>
      </div>
      <div
        v-show="questionnaire !== null"
        :class="{ 'border-bottom': !start_error }"
        >
        <!-- loggedUser -->
        <span class="flex items-center mt-4" v-if="'user' in questionnaire">
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">
            {{ $t('participant') }}:
            <b>{{ questionnaire.user.name }} - {{ questionnaire.user.email }}</b> <a href="#" @click="logout">{{ $t('entrar-com-outro-usuario') }}</a>
            </span>
        </span>
        <!-- startError -->
        <span :class="startErrorStyle()" @click="startErrorClick" v-if="start_error">
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-danger"
            ></div>
            <span class="text-lg text-danger"
            ><b><span v-html="start_error"></span></b></span
            >
        </span>
        <!-- showDurationInfo -->
        <span class="flex items-center mt-4" v-if="showDurationInfo && !startError">
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">
            {{ (isReturning? $t('Remainingtime'): $t('Duration')) + ':'}}
            <b>{{ durationClockInfo }}</b>
            </span>
        </span>
        <!-- DurationShortageInformation -->
        <span class="flex items-center ml-8 mt-0" v-if="showDurationInfo && !startError && showDurationShortageMessage">
            <span class="font-medium text-warning">
              <font-awesome-icon icon="arrow-up"/>
              {{ $t('atencao-duracao-reduzida-nao-e-possivel-ultrapassar-o-periodo-do-questionario') }}
            </span>
        </span>
        <!-- periodStartInfo -->
        <span
            class="flex items-center mt-4"
            v-if="periodStartInfo !== null || periodFinishInfo !== null"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span :class="periodInfoClass">
            {{ $t('periodo-do-questionario') }}
            <span v-if="periodStartInfo !== null">
                {{ $t('inicio-as') }} <b> {{ periodStartInfo }} </b>
            </span>
            <span v-if="periodFinishInfo !== null">
              <span v-if="periodStartInfo !== null"></span> {{ $t('termino-as') }}
                <b> {{ periodFinishInfo }} </b>
              </span>
            </span>
        </span>
        <span
            class="flex items-center mt-4 text-warning"
            v-if="entranceTimeLimitRemainderInfo !== null && !startError && withinPeriod"
        >
          <div
          class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
          ></div>
          <span class="text-lg">
            {{ _.get(entranceTimeLimitRemainderInfo, 'label', '') }}
          </span>
          <span class="pl-2 font-bold text-lg">
            {{ _.get(entranceTimeLimitRemainderInfo, 'time', '') }}
          </span>
        </span>
        <!-- showAnswersQuantityInfo -->
        <span class="flex items-center mt-4" v-if="showAnswersQuantityInfo">
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg" v-html-safe="showAnswersQuantityInfo"></span>
        </span>
        <!-- showAnswersInfo -->
        <span class="flex items-center mt-4" v-if="showAnswersInfo">
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showAnswersInfo }}</span>
        </span>
        <!-- showMultipleAttemptsInfo -->
        <span
            class="flex items-center mt-4"
            v-if="showMultipleAttemptsInfo"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showMultipleAttemptsInfo }}</span>
        </span>
        <!-- showViewResultsInfo -->
        <span class="flex items-center mt-4" v-if="showViewResultsInfo">
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showViewResultsInfo }}</span>
        </span>
        <!-- showLeaveInfo -->
        <span
            class="flex items-center mt-4 mb-6 leave-info"
            v-if="showLeaveInfo && !startError"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showLeaveInfo }}</span>
        </span>
        <!-- showDisallowQuestionReturn -->
        <span
            class="flex items-center mt-4 mb-6 leave-info"
            v-if="showDisallowQuestionReturn && !startError"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showDisallowQuestionReturn }}</span>
        </span>
        <!-- showForceFullscreen -->
        <span
            class="flex items-center mt-4 mb-6 leave-info"
            v-if="showForceFullscreen && !startError"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showForceFullscreen }}</span>
        </span>
        <!-- showCanPause -->
        <span
            class="flex items-center mt-4 mb-6 leave-info"
            v-if="showCanPause && !startError"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showForceFullscreen }}</span>
        </span>
        <!-- showIsPaused -->
        <span
            class="flex items-center mt-4 mb-6 leave-info"
            v-if="showIsPaused && !startError"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showForceFullscreen }}</span>
        </span>
        <!-- showHasEntranceTime -->
        <span
            class="flex items-center mt-4 mb-6 leave-info"
            v-if="showHasEntranceTime && !startError"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showForceFullscreen }}</span>
        </span>
        <!-- showHasVoiceControl -->
        <span
            class="flex items-center mt-4 mb-6 leave-info"
            v-if="showHasVoiceControl && !startError"
        >
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">{{ showVoiceControl }}</span>
            <span class="ml-2">
              <vs-button
                type="border"
                color="danger"
                @click="toggleVoiceControl"
                class="ml-4"
              >
                {{ voiceControlActivated ? $t('desativar-controle-por-voz') : $t('ativar-controle-por-voz') }}
              </vs-button>
            </span>
        </span>
        <!-- Connectivity proctoring test -->
        <span class="flex items-center mt-4" v-if="isProctored">
            <div
            class="ml-1 h-3 w-3 rounded-full mr-4 border-2 border-solid border-success"
            ></div>
            <span class="text-lg">
            <!-- {{ $t('participant') }}: -->
            <b>{{ $t('e-importante-fazer-o-teste-de-conectividade') }}</b>
            <vs-button
              type="border"
              color="warning"
              @click="connectivityTest"
              class="ml-4"
            >
              {{ $t('teste-de-conectividade') }}
            </vs-button>
            </span>
        </span>
    </div>


    <vs-popup :title="$t('teste-de-conectividade')" :active.sync="showProctoringTest" :fullscreen="true" v-esc="closeProctoringTest">
      <ProctoringTest ref="proctoringTestRef" v-if="showProctoringTest"></ProctoringTest>
    </vs-popup>

  </div>

</template>

<script>

import ProctoringTest from '@/views/apps/proctoring/ProctoringTest.vue'

export default {
  components: {
    ProctoringTest
  },
  props: {
    questionnaire: {
      type: Object,
      default: null
    },
    start_error: {
      type: Boolean,
      default: false
    },
    start_error_code: {
      type: Object,
      default: null
    },
    preview: {
      type: Boolean,
      default: false
    },
    /**
     * Property/Attribute for showing extra and specific information
     */
    show: {
      type: Object,
      default: () => ({})
    },
    voiceControlActivated: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showProctoringTest: false
    }
  },
  watch: {
    showProctoringTest(val) {
      if (!val) {
        const proctoringTestRef = this.$refs.proctoringTestRef
        if (proctoringTestRef) {
          proctoringTestRef.stopProctoringStream()
        }
      }
    }
  },
  computed: {
    showDurationShortageMessage() {
      return _.get(this, 'show.duration_shortage', false)
    },
    startError () {
      return this.start_error
    },
    entranceTimeLimit() {
      const time = parseFloat(this._.get(this.questionnaire, 'entrance_time_limit', 0))
      return time
    },
    entranceTimeLimitInfo() {
      const entranceTime = this.entranceTimeLimit
      const duration = this.$moment.duration(entranceTime, 'minutes')

      return this.$t('voce-tem-less-than-b-greater-than-duration-humanize-less-than-b-greater-than-para-entrar-na-avaliacao-apos-o-inicio', [duration.humanize()])
    },
    entranceTimeLimitRemainderInfo() {
      if (this.questionnaire.start_at && this.entranceTimeLimit) {
        const now = this.$moment()
        const questionnaireStart = this.$moment(this.questionnaire.start_at)
        const entranceTime = this.entranceTimeLimit

        const questionnaireLastEntranceMoment = _.cloneDeep(questionnaireStart)
        questionnaireLastEntranceMoment.add(entranceTime, 'minutes')

        const rawDifference = questionnaireLastEntranceMoment.diff(now)

        if (rawDifference < 0) return null

        return {
          label: this.$t('a-entrada-ao-questionario-sera-encerrada-as'),
          time: questionnaireLastEntranceMoment.format(this.$t('dd-mm-yyyy-kk-mm'))
        }
      } else {
        return null
      }
    },
    durationClockInfo() {
      return this._.get(this.questionnaire, 'durationClockInfo')
    },
    questionnaireName() {
      return this.questionnaire && this.questionnaire.name
        ? this.questionnaire.name
        : ''
    },
    showDurationInfo() {
      if (this.questionnaire === null) return false
      return (
        (this.questionnaire &&
          !this.isEmpty(this.questionnaire.answer_unfinished) &&
          this.questionnaire.duration > 0) ||
        (this.isEmpty(this.questionnaire.answer_unfinished) &&
          this.questionnaire.duration > 0)
      )
    },
    showAnswersQuantityInfo() {
      if (this.questionnaire && this.questionnaire.answers) {
        const answers = this.questionnaire.answers.filter(answer => {
          return answer.finished_at !== null
        })
        return answers.length > 0
          ? this.$t('voce-ja-concluiu-less-than-b-greater-than-answers-length-less-than-b-greater-than-vezes-este-questionario', [answers.length])
          : null
      }
      return null
    },
    showAnswersInfo() {
      return this.questionnaire && this.questionnaire.view_answers
        ? this.$t('voce-pode-consultar-as-respostas')
        : null
    },
    showMultipleAttemptsInfo() {
      return this.questionnaire && this.questionnaire.multipleAttempts
        ? this.$t('voce-pode-enviar-este-questionario-mais-de-uma-vez')
        : null
    },
    showViewResultsInfo() {
      return this.questionnaire && this.questionnaire.view_results
        ? this.$t('e-possivel-consultar-o-resultado-no-final')
        : null
    },
    showLeaveInfo() {
      if (!this.questionnaire || this.questionnaire.starErrorMessage) return null
      return this.questionnaire.leave
        ? this.$t('e-permitido-sair-e-continuar-este-questionario-posteriormente')
        : this.$t('apos-iniciar-nao-recarregue-e-nem-feche-esta-tela-nao-sera-permitido-iniciar-novamente')
    },
    showDisallowQuestionReturn() {
      if (!this.questionnaire || this.questionnaire.starErrorMessage) return null
      return this.questionnaire.disallow_question_return
        ? this.$t('apos-avancar-a-questao-nao-e-possivel-retornar-para-questao-anterior-por-isto-tenha-certeza-da-resposta-para-poder-avancar')
        : null
    },
    showForceFullscreen() {
      if (!this.questionnaire || this.questionnaire.starErrorMessage) return null
      return this.questionnaire.force_fullscreen
        ? this.$t('ao-iniciar-seu-navegador-ficara-em-modo-tela-cheia-nao-saia-do-modo-de-tela-cheia-estas-informacoes-serao-repassadas-para-o-responsavel-da-avaliacao-e-sua-avaliacao-podera-ser-encerrada-automaticamente')
        : null
    },
    showVoiceControl() {
      if (!this.questionnaire || this.questionnaire.starErrorMessage) return null
      return this.questionnaire.voice_control
        ? this.$t('e-possivel-realizar-este-questionario-usando-comandos-de-voz-diga-em-voz-alta-ler-instrucoes-para-obter-mais-detalhes')
        : null
    },
    showCanPause() {
      return false
    },
    showIsPaused() {
      return false
    },
    showHasEntranceTime() {
      return false
    },
    showHasVoiceControl() {
      return this.questionnaire && this.questionnaire.voice_control
    },
    periodInfoClass() {
      return this.withinPeriod ? this.$t('text-lg-text-success') : this.$t('text-xl-text-danger')
    },
    periodStartInfo() {
      return this.questionnaire && this.questionnaire.start_at
        ? this.questionnaire.start_at_formatted
        : null
    },
    periodFinishInfo() {
      return this.questionnaire && this.questionnaire.finish_at
        ? this.questionnaire.finish_at_formatted
        : null
    },
    withinPeriod() {
      if (this.preview) {
        return true
      }

      const start = this.$moment(this.questionnaire.start_at)
      const finish = this.$moment(this.questionnaire.finish_at)
      const now = this.$moment()
      if (start.isValid() && finish.isValid()) {
        // Both finish and start dates are valid.
        return now.isBetween(start, finish)

      } else if (start.isValid() && !finish.isValid()) {
        // The teacher only set a finish period date.
        return now.isAfter(start)

      } else if (!start.isValid() && finish.isValid()) {
        // The teacher only set a start period date
        return now.isBefore(finish)

      } else if (!start.isValid() && !finish.isValid()) {
        // The start and finish dates are null or invalid
        return true
      }
      return false
    },
    isProctored() {
      const activeProctoring = this.questionnaire.active_proctoring
      return activeProctoring && activeProctoring.status === 'E'
    }
  },
  methods: {
    startErrorClick() {
      if (this.start_error_code === 1) {
        this.$router.push(`${this.$router.currentRoute.path}/results`)
      }
    },
    startErrorStyle() {
      const base = 'flex items-center mt-4'
      let extra = ''
      if (this.start_error_code === 1) {
        extra += ' hover:underline cursor-pointer'
      }
      return `${base}${extra}`
    },
    clockTimeout() {
      // Reimplement
    },
    formatClockInfo(t) {
      let clock = ''
      const days = t.days > 0 ? `${t.days}` : null
      const hours =
        t.hours > 0 ? (t.hours >= 10 ? `${t.hours}` : `0${t.hours}`) : '00'
      const minuts =
        t.minutes > 0
          ? t.minutes >= 10
            ? `${t.minutes}`
            : `0${t.minutes}`
          : '00'
      const seconds =
        t.seconds > 0
          ? t.seconds >= 10
            ? `${t.seconds}`
            : `0${t.seconds}`
          : '00'

      if (days > 0) {
        clock += `${days} dias `
      }

      clock += this.$t('hours-hs-minuts-min-seconds-seg', [hours, minuts, seconds])
      return clock
    },
    logout() {
      this.$store.dispatch('auth/logout', {vs: this.$vs})
    },
    toggleVoiceControl() {
      this.$emit('toggleVoiceControl', !this.voiceControlActivated)
    },
    connectivityTest() {
      this.showProctoringTest = true
      const proctoringTestRef = this.$refs.proctoringTestRef
      if (proctoringTestRef) {
        proctoringTestRef.enableUserMedia()
      }
    },
    closeProctoringTest() {
      this.showProctoringTest = false
      const proctoringTestRef = this.$refs.proctoringTestRef
      if (proctoringTestRef) {
        proctoringTestRef.stopProctoringStream()
      }
    },
  }
}
</script>

<style scoped>

.vs-card--content {
  max-height: 600px;
  overflow: auto;
}

</style>
