<template>

  <div>

    <div class="flex flex-col overflow-hidden" v-if="step === 1">
        <header class="w-full text-center border-b border-grey p-4">
          <logo :reduce="false" logoStyle="max-width: 450%; height: auto; max-height: 40px;"/>
        </header>

        <main class="flex-1 pr-24 pl-24">

           <div class="grid grid-cols-1 sm:grid-cols-2 h-full p-8">

            <div class="h-full">

              <div class="flex mt-4 justify-center">
                  <h3>{{ $t('visualizacao-do-dispositivo') }}</h3>
              </div>

              <div v-show="userMediaStream !== null" class="flex items-center justify-center mt-4">
                  <video
                    v-show="userMediaStream !== null"
                    id="video-document-test"
                    muted
                    disablePictureInPicture
                    class="video-player-document transform w-full">
                  </video>
              </div>

              <div v-if="getDeviceVideoError !== null" class="flex items-center justify-center mt-4">
                <img src="@/assets/images/no_image_placeholder.png" alt="$t('no-camera')" style="max-width: 300px;"/>
              </div>

              <div class="flex items-center justify-center mt-4">
                <vs-divider>{{ $t('validacao-de-microfone') }}</vs-divider>
              </div>

              <div class="grid grid-rows-2 mt-4">

                <div class="flex items-center justify-center">
                    <vs-progress :percent="micVolume" color="success">success</vs-progress>
                </div>

                <div class="flex items-center justify-center">
                    <span class="mic-config mt-2 text-center" v-if="!micSuccess">{{ $t('fale-algo-em-voz-alta-para-validar-a-configuracao-do-seu-microfone') }}</span>
                    <span class="mic-succes mt-2" v-if="micSuccess">{{`${$t('sucesso')}!`}}</span>
                </div>
              </div>

            </div>

            <div class="grid grid-rows-6 p-4">

              <div class="pt-8">
                <div v-if="videoDevices.length > 0">
                  <vs-select
                    class="w-full"
                    :label="$t('entradas-de-video-camera')"
                    v-model="selectedVideoDeviceId"
                    @input="enableUserMedia"
                  >
                      <vs-select-item :key="index" :value="device.deviceId" :text="device.label" v-for="(device,index) in videoDevices" />
                  </vs-select>

                  <vs-alert
                    v-if="getDeviceVideoError !== null"
                    active="true"
                    color="danger"
                    icon="new_releases"
                    class="mt-2 h-auto">
                    <div v-html="getDeviceVideoError"></div>
                  </vs-alert>

                </div>
              </div>

              <div v-if="audioDevices && audioDevices.length > 0" class="mt-4">
                <vs-select
                  class="w-full"
                  :label="$t('entradas-de-audio-microfone')"
                  v-model="selectedAudioDeviceId"
                  @input="enableUserMedia"
                >
                  <vs-select-item :key="index" :value="device.deviceId" :text="device.label" v-for="(device,index) in audioDevices" />
                </vs-select>

                <vs-alert
                  v-if="getDeviceAudioError !== null"
                  active="true"
                  color="danger"
                  icon="new_releases"
                  class="mt-2 h-auto">
                  <div v-html="getDeviceAudioError"></div>
                </vs-alert>

              </div>

              <div class="flex items-start justify-end">
                  <vs-button
                    class="mr-2"
                    @click="verify"
                    icon="icon icon-log-in"
                    icon-pack="feather"
                    :disabled="!micSuccess || userMediaStream === null"
                  >
                      {{ $t('avancar') }}
                  </vs-button>
              </div>

            </div>

          </div>

        </main>
    </div>

    <div class="flex w-full bg-img vx-row no-gutter items-center justify-center" v-else>

      <div class="flex flex-col ...">
        <div class="mb-4">
          <logo :reduce="false" logoStyle="max-width: 450%; height: auto; max-height: 40px;"/>
        </div>
        <div class="text-center mb-6">
          <h1>{{ $t('checking-connectivity') }}</h1>
        </div>
        <div class="flex items-center justify-center">
          <div class="grid sm:grid-cols-2 grid-cols-1 gap-8 p-4" style="max-width: 80%;">

            <div v-for="config in configs" :key="config" :class="config.class">
              <vs-card>
                  <div slot="header" style="text-align: center" class="font-bold">
                    {{ $t(config.title) }}
                  </div>
                  <div class="grid grid-cols-2">

                    <div class="flex items-center text-lg">
                      {{ $t(config.description) }}
                    </div>
                    <div>
                      <lottie-animation
                          :path="config.icon"
                          :width="128"
                          :height="128"
                      />
                    </div>

                  </div>

                  <!-- Camera -->
                  <div class="grid grid-cols-1" v-for="item in config.items" :key="item">

                    <div class="grid grid-cols-12">

                      <div class="text-xl col-span-1">
                        <font-awesome-icon
                          :icon="item.icon"
                          class="mr-4"
                        />
                      </div>

                      <div class="text-xl col-span-6">
                        {{ $t(item.name) }}
                      </div>

                      <div class="text-xl col-span-5">

                        {{ item.message }}

                        <font-awesome-icon
                          v-if="item.status === proctoringTestStatusSuccess"
                          icon="check-circle"
                          class="text-green"
                        />
                        <font-awesome-icon
                          v-if="item.status === proctoringTestStatusError"
                          icon="exclamation-circle"
                          class="text-red"
                        />
                        <font-awesome-icon
                          v-if="item.status === proctoringTestStatusWarning"
                          icon="exclamation-circle"
                          class="text-orange"
                          :title="item.description ? item.description : ''"
                        />

                      </div>

                    </div>

                    <div class="grid grid-cols-12 pt-2" v-if="item.description">

                      <div class="text-xl col-span-1">
                        <font-awesome-icon
                          icon="exclamation-circle"
                          class="text-orange"
                        />
                      </div>

                      <div class="col-span-11 flex items-center">
                        {{ $t(item.description) }}
                      </div>

                    </div>

                  </div>

                </vs-card>
            </div>

          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>

import Logo from '@/layouts/components/Logo.vue'
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'
import Bowser from 'bowser'
import ProctoringTestLogService from '@/services/api/ProctoringTestLogService'
import { PROCTORING_TEST_STATUS } from '@/util/Enums'

export default {

  components: {
    Logo,
    LottieAnimation
  },

  props: {
    content_questionnaire_id: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    step: 1,
    videoDevices: [],
    audioDevices: [],
    micSuccess: false,
    micVolume: 0,
    userMediaEnabled: false,
    captureUserMediaError: false,
    getDeviceVideoError: null,
    getDeviceAudioError: null,
    micSoundInterval: false,
    userMediaStream: null,
    selectedAudioDeviceId: null,
    selectedVideoDeviceId: null,
    devices: [],
    mediaStream: null,
    proctoringTestLogService: null,
    data: {
      upload_speed: null,
      download_speed: null,
      user_media_stream: null,
      microphone: null,
      device_memory: null,
      device_processor: null,
      video_recorder: null,
      javascript: null,
      cookies: null,
      screenshare: null,
      os_name: null,
      os_version: null,
      browser_name: null,
      browser_version: null,
      proctoring_compability: null,
      audio_devices: null,
      video_devices: null
    },
    configs: [
      {
        title: 'proctoring_hardware_configuration',
        description: 'proctoring_hardware_configuration_description',
        icon: 'assets/lottie/hardware.json',
        items: [
          {
            name: 'proctoring_config_camera',
            icon: 'camera',
            status: false,
            message: '--'
          },
          {
            name: 'proctoring_config_microphone',
            icon: 'microphone',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_ram',
            icon: 'memory',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_processor',
            icon: 'microchip',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_video_recorder',
            icon: 'video',
            status: null,
            message: '--'
          }
        ]
      },
      {
        title: 'proctoring_software_configuration',
        description: 'proctoring_software_configuration_description',
        icon: 'assets/lottie/software.json',
        class: 'card-software',
        items: [
          {
            name: 'proctoring_config_desktop',
            icon: 'desktop',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_browser_version',
            icon: 'globe',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_web_compability',
            icon: 'globe',
            status: null,
            message: '--'
          }
        ]
      },
      {
        title: 'proctoring_browser_configuration',
        description: 'proctoring_browser_configuration_description',
        icon: 'assets/lottie/browser.json',
        class: 'card-browser',
        items: [
          {
            name: 'proctoring_config_javascript',
            icon: 'code',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_cookie',
            icon: 'cookie',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_screenshare',
            icon: 'share-alt',
            status: null,
            message: '--'
          }
        ]
      },
      {
        title: 'proctoring_network_configuration',
        description: 'proctoring_network_configuration_description',
        icon: 'assets/lottie/globe.json',
        items: [
          {
            name: 'proctoring_config_download',
            icon: 'download',
            status: null,
            message: '--'
          },
          {
            name: 'proctoring_config_upload',
            icon: 'upload',
            status: null,
            message: '--'
          },
          //   {
          //     name: 'proctoring_config_audio_bitrate',
          //     icon: 'file-audio',
          //     status: null,
          //     message: '--'
          //   },
          //   {
          //     name: 'proctoring_config_video_bitrate',
          //     icon: 'video',
          //     status: null,
          //     message: '--'
          //   },
          // {
          //   name: 'proctoring_config_websocket',
          //   icon: 'arrows-alt-h',
          //   status: null,
          //   message: '--'
          // }
        ]
      }
    ],
    proctoringTestStatusSuccess: PROCTORING_TEST_STATUS.SUCCESS,
    proctoringTestStatusWarning: PROCTORING_TEST_STATUS.WARNING,
    proctoringTestStatusError: PROCTORING_TEST_STATUS.EERROR
  }),

  computed: {

  },

  methods: {
    enableUserMedia() {
      this.$vs.loading()

      this.micSuccess = false
      this.audioDevices = []
      this.videoDevices = []

      // if (this.micSoundInterval) {
      //   clearInterval(this.micSoundInterval)
      // }

      /**
       * Calling getUserMedia to get permission to enumerate devices. Some browsers like Chrome
       * don't gave access to enumerateDevices before user enable camera permission.
       */
      const constraints = {
        audio: { deviceId: undefined },
        video: { deviceId: undefined }
      }

      this.startCaptureUserMedia(constraints, this, null, false).then(tempStream => {
        this.stopStream(tempStream)
        this.getDevices()
      }, () => {
        this.getDevices()
      })
    },
    getDevices() {

      this.getDeviceVideoError = null
      this.getDeviceAudioError = null

      this.enumerateDevices().then(devices => {

        devices = this.$utils.array.uniqBy(devices, (item) => {
          return item.deviceId
        })

        devices.forEach(device => {
          if (device.kind === 'audioinput') {
            this.audioDevices.push(device)
          } else if (device.kind === 'videoinput') {
            this.videoDevices.push(device)
          }
        })

        if (this.audioDevices.length > 0) {
          if (!this.selectedAudioDeviceId) {
            this.selectedAudioDeviceId = this.audioDevices[0].deviceId
          }
          this.data.audio_devices = this.audioDevices
        }

        if (this.videoDevices.length > 0) {
          if (!this.selectedVideoDeviceId) {
            this.selectedVideoDeviceId = this.videoDevices[0].deviceId
          }
          this.data.video_devices = this.videoDevices
        }

        this.userMediaEnabled = true
        this.captureVideoStream()

      }, error => {
        this.captureVideoStream()
      })
    },
    captureVideoStream() {

      const videoConstraits = {
        video: {
          deviceId: {
            exact: this.selectedVideoDeviceId
          },
          width: { max: 800 },  // Define a largura máxima do vídeo em pixels
          height: { max: 600 }, // Define a altura máxima do vídeo em pixels
          frameRate: { max: 15 } // Define a taxa máxima de quadros por segundo (fps)
        },
        audio: false
      }

      // Capture video stream
      this.startCaptureUserMedia(videoConstraits, this, 'video', false).then(stream => {

        this.userMediaStream = stream
        const waitNextScreenTimeout = setInterval(() => {
          const videoDocument = document.getElementById('video-document-test')

          if (videoDocument) {
            clearInterval(waitNextScreenTimeout)
            videoDocument.srcObject = stream
            videoDocument.play()
          }
        }, 100)

        this.captureAudioStream()

      }, error => {
        this.captureAudioStream()
        this.getDeviceVideoError = error
        this.$vs.loading.close()
      })
    },

    captureAudioStream() {

      const audioConstraits = {
        audio: {
          deviceId: {
            exact: this.selectedAudioDeviceId
          }
        },
        video: false
      }

      this.startCaptureUserMedia(audioConstraits, this, 'audio', false).then(stream => {

        if (stream.getAudioTracks() && stream.getAudioTracks()[0]) {
          stream.getAudioTracks()[0].enabled = true
        }

        if (this.userMediaStream) {
          this.userMediaStream.addTrack(stream.getAudioTracks()[0])
        }

        const audioContext = new AudioContext()
        const analyser = audioContext.createAnalyser()
        audioContext.createMediaStreamSource(this.userMediaStream).connect(analyser)
        const pcmData = new Float32Array(analyser.fftSize)

        this.micSoundInterval = setInterval(() => {

          if (analyser && analyser.getFloatTimeDomainData && typeof analyser.getFloatTimeDomainData === 'function') {
            analyser.getFloatTimeDomainData(pcmData)
          }

          let sumSquares = 0.0
          for (const amplitude of pcmData) { sumSquares += amplitude * amplitude }
          this.micVolume = Math.sqrt(sumSquares / pcmData.length) * 1000

          if (this.micVolume > 30) {
            this.micSuccess = true
          }
        }, 40)

        this.userMediaEnabled = true
        this.$vs.loading.close()

      }, error => {
        this.getDeviceAudioError = error
        this.$vs.loading.close()

        if ((this.audioDevices && this.audioDevices.length > 0)
              && this.videoDevices && this.videoDevices.length > 0) {
          this.userMediaEnabled = true
        } else {
          this.captureUserMediaError = true
        }
      })
    },
    verify() {

      this.step = 2

      this.startHardwareConfig()
      this.startBrowserConfig()
      this.startSoftwareConfig()
      this.startNetworkConfig()
    },

    startHardwareConfig() {

      const successMessage = this.$t('success')
      const errorMessage = this.$t('erro')
      const warningMessage = this.$t('alerta')

      // Checking camera
      let item = this.configs[0].items[0]
      if (this.userMediaStream !== null) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = successMessage
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = errorMessage
      }
      this.data.user_media_stream = item.status

      // Checking microphone
      item = this.configs[0].items[1]
      if (this.micSuccess !== null) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = successMessage
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = errorMessage
      }
      this.data.microphone = item.status

      // Ram memory
      item = this.configs[0].items[2]
      const deviceMemory = navigator.deviceMemory
      if (deviceMemory) {
        if (deviceMemory >= 4) {
          item.status = PROCTORING_TEST_STATUS.SUCCESS
          item.message = successMessage
        }
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = '--'
      }
      this.data.device_memory = item.status

      // Processor
      item = this.configs[0].items[3]
      if (navigator.hardwareConcurrency) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = successMessage
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = errorMessage
      }
      this.data.device_processor = item.status

      // Video recorder
      item = this.configs[0].items[4]
      item.message = this.$t('proctoring_config_verifying')
      this.detectMediaRecorderPerformance(this.userMediaStream, this.getRecordMimeType()).then(data => {
        item = this.configs[0].items[4]
        if (data.fps >= 1) {
          item.status = PROCTORING_TEST_STATUS.SUCCESS
          item.message = successMessage
        } else if (data.fps < 1 && data.fps >= 0.7) {
          item.status = PROCTORING_TEST_STATUS.WARNING
          item.message = warningMessage
          item.description = this.$t('recorder-performance-description-warning')
        }  else {
          item.status = PROCTORING_TEST_STATUS.EERROR
          item.message = errorMessage
        }
        this.data.video_recorder = data
      })

    },

    startBrowserConfig() {

      const successMessage = this.$t('success')
      const errorMessage = this.$t('erro')

      // Checking Javascript
      let item = this.configs[2].items[0]
      if (item) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = successMessage
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = errorMessage
      }
      this.data.javascript = item.status

      // Checking cookies
      item = this.configs[2].items[1]
      if (navigator.cookieEnabled) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = successMessage
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = errorMessage
      }
      this.data.cookies = item.status

      // Screenshare
      item = this.configs[2].items[2]
      if (navigator.mediaDevices && 'getDisplayMedia' in navigator.mediaDevices) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = successMessage
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = errorMessage
      }
      this.data.screenshare = item.status

    },

    startSoftwareConfig() {

      // Checking OS
      const browser = Bowser.getParser(navigator.userAgent)
      let item = this.configs[1].items[0]
      if (item) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = `${browser.getOSName()} ${browser.getOSVersion()}`
        this.data.os_name = browser.getOSName()
        this.data.os_version = browser.getOSVersion()
      }

      // Browser
      item = this.configs[1].items[1]
      if (item) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        let browserVersion = browser.getBrowserVersion()
        browserVersion = browserVersion.split('.')
        item.message = `${browser.getBrowserName()} ${browserVersion[0]}`
        this.data.browser_name = browser.getBrowserName()
        this.data.browser_version = browserVersion[0]
      }

      // Compability
      item = this.configs[1].items[2]
      if (this.proctoringCompability()) {
        item.status = PROCTORING_TEST_STATUS.SUCCESS
        item.message = this.$t('sim')
      } else {
        item.status = PROCTORING_TEST_STATUS.ERROR
        item.message = this.$t('nao')
      }
      this.data.proctoring_compability = item.status

    },

    startNetworkConfig() {

      // Checking OS
      const browser = Bowser.getParser(navigator.userAgent)
      const item = this.configs[3].items[0]
      item.message = this.$t('proctoring_config_verifying')

      let elapsedTime = 0
      let fileSize = 0

      this.$utils.browser.detectConnectionSpeed(`${process.env.VUE_APP_DOWNLOAD_TEST_URL}_1.jpg`).then(result => {

        elapsedTime += result.elapsedTime
        fileSize += result.fileSize

        item.message = `(${result.speed} Mbps) ${this.$t('proctoring_config_verifying')}...`

        this.$utils.browser.detectConnectionSpeed(`${process.env.VUE_APP_DOWNLOAD_TEST_URL}_2.jpg`).then(result => {

          elapsedTime += result.elapsedTime
          fileSize += result.fileSize

          item.message = `(${result.speed} Mbps) ${this.$t('proctoring_config_verifying')}...`

          this.$utils.browser.detectConnectionSpeed(`${process.env.VUE_APP_DOWNLOAD_TEST_URL}_3.jpg`).then(result => {

            elapsedTime += result.elapsedTime
            fileSize += result.fileSize

            item.message = `(${result.speed} Mbps) ${this.$t('proctoring_config_verifying')}...`

            this.$utils.browser.detectConnectionSpeed(`${process.env.VUE_APP_DOWNLOAD_TEST_URL}_4.jpg`).then(result => {

              elapsedTime += result.elapsedTime
              fileSize += result.fileSize

              item.status = PROCTORING_TEST_STATUS.SUCCESS
              item.message = 'Ok'

              this.data.download_speed = ((fileSize * 8) / (elapsedTime / 1000) / 1024 / 1024).toFixed(2)
              item.message = `${this.data.download_speed} Mbps`

              this.detectUploadSpeed(0, {dataSize: 0, elapsedTime: 0}, 50)

            }, error => {
              item.message = this.$t('erro')
              console.log('connection2 speed error', error)
            })

          }, error => {
            item.message = this.$t('erro')
            console.log('connection2 speed error', error)
          })

        }, error => {
          item.message = this.$t('erro')
          console.log('connection2 speed error', error)
        })

      }, error => {
        item.message = this.$t('erro')
        console.log('connection speed error', error)
      })

    },

    detectUploadSpeed(count, resultParam, maxCount) {

      const item = this.configs[3].items[1]

      this.$utils.browser.detectUploadSpeed(1024).then((result) => {

        this.data.upload_speed = 0
        if (resultParam.dataSize > 0) {
          this.data.upload_speed = ((resultParam.dataSize * 8) / (resultParam.elapsedTime / 1000) / 1024).toFixed(2)
        }

        if (count < maxCount) {

          if (this.data.upload_speed > 0) {
            item.message = `(${this.data.upload_speed} Mbps) ${this.$t('proctoring_config_verifying')}`
          }

          resultParam.dataSize +=  +result.dataSize
          resultParam.elapsedTime +=  +result.elapsedTime
          return this.detectUploadSpeed(++count, resultParam, maxCount)
        } else {
          item.message = `${this.data.upload_speed} Mbps`

          if (this.data.upload_speed < 15) {
            item.status = PROCTORING_TEST_STATUS.ERROR
          } else {
            item.status = PROCTORING_TEST_STATUS.SUCCESS
          }

          const logParams = {
            'content_questionnaire_id': this.content_questionnaire_id,
            'data': this.data
          }
          this.proctoringTestLogService.create(logParams)

        }
      })
    },

    itemStatus(item) {
      let status = this.$t('proctoring_config_verifying')

      switch (item.status) {
      case true:
        status = this.$t('success')
        break
      case false:
        status = this.$t('erro')
        break
      }
      return status
    },

    stopProctoringStream() {
      this.stopStream(this.userMediaStream)
    }
  },

  created() {
    this.enableUserMedia()
  },

  beforeMount() {
    this.proctoringTestLogService = ProctoringTestLogService.build(this.$vs)
  },

  beforeDestroy() {
    this.stopStream(this.userMediaStream)
  }
}
</script>

<style lang="scss">

.logo {
  position: absolute;
  left: 10px;
  top: 10px;
  width: 150px;
}

.card-software > .con-vs-card {
  min-height: 295px;
}

.card-browser > .con-vs-card {
  min-height: 321px;
}

.video-player {
  width: 100%;
  padding: 10px;
  position: relative;
  z-index: 999999999999;
}

.monitor-video-player {
  position: relative;
  width: 100% !important;
  left: 0;
  z-index: 999999999999;
}

.video-player-document {
  max-width: 500px;
}

</style>
